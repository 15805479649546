import React, {Component} from 'react';

import style from './index.module.sass';
import phone from '../../assets/imgs/iPhone.png'
import clock from '../../assets/imgs/clock.png'
import point from '../../assets/imgs/point.png'
import metro_bot from '../../assets/imgs/mosmetro_sign_bot.svg';
import metro_otr from '../../assets/imgs/mosmetro_sign_otr.svg';
import Tabber from "../Tabber";

class Contacts extends Component {
    constructor(props) {
        super(props);
        this.contactsRef = React.createRef();
        this.state = {
            map: '',
            map2: '',
        };
    }


    componentDidMount() {
        const listener2 = () => {
            let getBodyScrollTop = function () {
                const el = document.scrollingElement || document.documentElement;
                return el.scrollTop
            };

            if (!this.state.map) {
                console.log('!this.state.map', this.state.map);
                const elementRect = this.contactsRef.current.getBoundingClientRect();
                let elemOffset = Math.round(elementRect.top + document.body.getBoundingClientRect().top - elementRect.height);

                console.log(document.body.getBoundingClientRect());
                console.log(elementRect.height);
                console.log(getBodyScrollTop());
                console.log(elemOffset);
                if (getBodyScrollTop() > elemOffset) {
                    import("../Map")
                        .then(({ Map }) => {
                            console.log('load Map');
                            this.setState({
                                map: Map
                            })
                        })
                        .catch(err => {
                            console.log(err)
                            // Handle failure
                        });
                    import("../Map2")
                        .then(({ Map2 }) => {
                            this.setState({
                                map2: Map2
                            })
                        })
                        .catch(err => {
                            console.log(err)
                            // Handle failure
                        });
                }
            }
        };
        listener2();
        document.addEventListener('scroll', listener2);
        document.addEventListener('touchmove', listener2);
    }

    render() {
        let Map = this.state.map || (() => <div/>);
        let Map2 = this.state.map2 || (() => <div/>)
        let leftContentItem = <div className={style.contactsItem}  ref={this.contactsRef}>
            <div className={style.contactsInfo}>
                <article>
                    <div className={style.contacts}>
                        <div>
                            <img src={phone} alt=""/>
                            <a href="tel:+78006009580">
                                8-800-600-95-80
                            </a>
                        </div>
                        <div>
                            бесплатный звонок по РФ
                        </div>
                    </div>
                    <div className={style.address}>
                        <span> 2-й Муринский проспект, дом 36. стр.1 , пом.181Н</span>
                        <div>( <img src={point} alt=""/>Площадь мужества)</div>
                    </div>

                </article>
                <article>
                    <img src={clock} alt=""/>
                    <p>Пн-Пт: с 10:00 до 19:00<br/>
                        Сб-Вс: выходной</p>

                </article>
            </div>
            <Map/>
        </div>;
        let rightContentItem = <div className={style.contactsItem}  ref={this.contactsRef}>
            <div className={style.contactsInfo}>
                <article>
                    <div className={style.contacts}>
                        <div>
                            <img src={phone} alt=""/>
                            <a href="tel:+78006009580">
                                8-800-600-95-80
                            </a>
                        </div>
                        <div>
                            бесплатный звонок по РФ
                        </div>
                    </div>
                    <div className={style.address}>
                        <span className={style.nowrap}>Берёзовая аллея, 5Ас5</span>
                        <div><img src={metro_bot} alt="Ботанический сад" />Ботанический сад — 1300 м<br />  <img src={metro_otr} alt="Отрадное" />Отрадное — 2300 м</div>
                    </div>

                </article>
                <article>
                    <img src={clock} alt=""/>
                    <p>Пн-Пт: с 10:00 до 19:00<br/>
                        Сб-Вс: выходной</p>

                </article>
            </div>
            <Map2/>
        </div>;

        return (
            <div className={style.Contacts}>
                <Tabber
                    leftHeaderText={'Санкт-Петербург'}
                    rightHeaderText={'Москва'}
                    leftContentItem={leftContentItem}
                    rightContentItem={rightContentItem}
                    customTabberClass={style.disableMoscow}
                />

            </div>
        );
    }
}

export default Contacts;
